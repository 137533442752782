

@import './variables.scss';
@import '~bootstrap/scss/bootstrap.scss';


$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/regular.scss";

@import "~select2/dist/css/select2.min.css";

@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css";