
// customized bootstrap variables


$primary: #337ab7;
$danger:  #dc3545;
$warning: #F96700;
$success: #28a745;

// $theme-colors: (
//   "primary": #337ab7,
//   "warning": $warning,
//   "success": $success,
//   "danger": $danger
// );

$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base:          .9rem;

$min-contrast-ratio:   3 !default;